<template>
    <!-- 发布项目 -->
    <div class="container">
        <BreadcrumbNav :pageName="$t('myPublishedProjects.editProject')"></BreadcrumbNav>
        <div class="add_box auto">
            <!--   one   -->
            <div class="Add_nav ">
                <div :class="{nav_active:detailData.projectType==1}" class="fl nav_item cur"
                     v-if="detailData.projectType==1">{{
                    $t("home.findTalents")
                    }}
                </div>
                <div :class="{nav_active:detailData.projectType==2}" class="fl nav_item cur"
                     v-if="detailData.projectType==2">{{ $t("home.findMoney") }}
                </div>
                <div :class="{nav_active:detailData.projectType==3}" class="fl nav_item cur"
                     v-if="detailData.projectType==3">{{
                    $t("home.findWisdom")
                    }}
                </div>
                <div class="clearfix"></div>
            </div>
            <!--   two   -->
            <div class="upload_box">
                <div class="upload_one">
                    <!--图片、gif、视频选择--START-->
                    <ul>
                        <li :class="{upload_active:coverChecked=='img'}" @click="checkCover('img')" class="fl cur">
                            <div class="fl upload_hint_img">
                                <img alt="" src="../../assets/images/icon_uplx1@2x.png" width="36px">
                            </div>
                            <div class="fl upload_hint" style="padding-top: 24px">
                                <h2>One high resolution
                                    mage</h2>
                                <p>PNG,JPG,GIF+
                                    Cropping</p>
                            </div>
                            <span class="line"></span>
                        </li>
                        <li :class="{upload_active:coverChecked=='gif'}" @click="checkCover('gif')" class="fl cur">
                            <div class="fl upload_hint_img">
                                <img alt="" src="../../assets/images/icon_uplx2@2x.png" width="36px">
                            </div>
                            <div class="fl upload_hint" style="padding-top: 42px">
                                <h2>Animated GIF</h2>
                                <p>376x250,695x460</p>
                            </div>
                            <span class="line"></span>
                        </li>
                        <li :class="{upload_active:coverChecked=='video'}" @click="checkCover('video')" class="fl cur">
                            <div class="fl upload_hint_img">
                                <img alt="" src="../../assets/images/icon_uplx3@2x.png" width="36px">
                            </div>
                            <div class="fl upload_hint" style="padding-top: 42px">
                                <h2>Videos</h2>
                                <p style="width: 200px">MP4,4:3,＜24 seconds</p>
                            </div>
                        </li>
                        <div class="clearfix"></div>
                    </ul>
                    <!--图片、gif、视频选择--END-->
                    <div class="upload_hint_box">
                        <el-upload :accept="mediaType" :action="actionUrl" :before-upload="handleBefore"
                                   :on-success="handleSuccess" :show-file-list="false" class="upload-demo"
                                   drag name="file" ref="uploadCover" v-show="!this.projectCoverUrl">
                        </el-upload>
                        <div class="aa" style="height: 100%;margin-top: 28px;" v-if="!this.projectCoverUrl">
                            <img alt="" src="../../assets/images/icon_up_g@2x.png" width="172px">
                            <h2>Drag and drop an media</h2>
                            <p>or browse to choose a file</p>
                            <div>(695x460 or larger recommended, up to 10MB each)</div>
                        </div>
                        <!--选中图以后显示-->
                        <div class="cover-show" style="height: 100%" v-if="this.projectCoverUrl">
                            <img :src="this.projectCoverUrl" alt="" style="width: 100%;" v-if="this.coverType==2">
                            <video :src="this.projectCoverUrl" controls="controls" style="width: 100%;"
                                   v-if="this.coverType==1"></video>
                            <el-button @click="clearCover" class="cancel-btn" icon="el-icon-delete"
                                       type="info"></el-button>
                        </div>

                    </div>
                </div>
                <div class="upload_two">
                    <UploadBanner ref="uploadBanner"/>
                </div>
            </div>
            <!--  three (找人) -->
            <div class="three_box" v-if="this.detailData.projectType==1">
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.position") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" type="text" v-model="attr1.projectName">
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show1">职位名称不能为空</span>-->
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.treatment") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" oninput="value=value.replace(/[^\d.]/g,'')"
                               type="text" v-model="attr1.money">
                        <div class="fr">{{ $t("publish.dollar") }}</div>
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show2">待遇不能为空</span>-->
                </div>
                <div class="three_item other-financing" style="margin-bottom: 50px">
                    <div class="three_title">
                        {{ $t("publish.brief") }}
                    </div>
                    <el-input
                            :placeholder="$t('publish.inputStr')"
                            :rows="2"
                            type="textarea"
                            v-model="attr1.projectIntro">
                    </el-input>
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.releaseMode") }}
                    </div>
                    <div class="choose_way">
                        <img alt=""
                             class="cur" src="../../assets/images/radio_s2@2x.png" v-if="attr1.publishType == 1"
                             width="16">
                        <img @click="attr1.publishType = 1"
                             alt="" class="cur" src="../../assets/images/radio_n2@2x.png" v-else width="16">
                        <span class="choose_title">{{ $t("publish.publicRelease") }}</span>
                        <span class="choose_hint">{{ $t("publish.vipSketch") }}</span>
                        <img @click="attr1.isHiddenIssuer = 1"
                             alt="" class="cur" src="../../assets/images/radio_s@2x.png" style="margin-bottom: 1px;"
                             v-if="attr1.isHiddenIssuer==2" width="14px">
                        <img @click="attr1.isHiddenIssuer = 2" alt="" class="cur"
                             src="../../assets/images/radio_n@2x.png" style="margin-bottom: 1px;" v-else width="14px">
                        <span class="choose_title_two">{{ $t("publish.hide") }}</span>
                    </div>
                    <div class="choose_way">
                        <img alt=""
                             class="cur" src="../../assets/images/radio_s2@2x.png" v-if="attr1.publishType == 2"
                             width="16">
                        <img @click="()=>{this.attr1.publishType = 2}"
                             alt="" class="cur" src="../../assets/images/radio_n2@2x.png" v-else width="16">
                        <span class="choose_title">{{ $t("publish.private") }}</span>
                        <span class="choose_hint" style="margin-left: 20px">
              <el-tooltip :content="dictLabel" placement="top">
                <img alt="" src="../../assets/images/icon_why@2x.png" style="margin-right: 0;margin-bottom: 2px;"
                     width="13">
              </el-tooltip>
              {{ $t("publish.whatSketch") }}
            </span>
                    </div>
                </div>
            </div>
            <!--找钱-->
            <div class="three_box" v-else-if="this.detailData.projectType==2">
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.projectName") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" type="text" v-model="attr2.projectName">
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show3">项目名称不能为空</span>-->
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.financingStage") }}
                    </div>
                    <div class="input_box financing-stage">
                        <el-select :placeholder="$t('publish.input')" v-model="attr2.financingStage">
                            <el-option :key="item.value" :label="item.label" :value="item.value"
                                       v-for="item in options">
                            </el-option>
                        </el-select>
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show4">融资阶段不能为空</span>-->
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.financingAmount") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" oninput="value=value.replace(/[^\d.]/g,'')"
                               type="text" v-model="attr2.money">
                        <div class="fr">{{ $t("publish.dollar") }}</div>
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show5">融资金额不能为空</span>-->
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.transferOfShares") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" oninput="value=value.replace(/[^\d.]/g,'')"
                               type="text"
                               v-model="attr2.equity">
                        <div class="fr">%</div>
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show6">出让股权不能为空</span>-->
                </div>
                <div class="three_item other-financing" style="margin-bottom: 50px">
                    <div class="three_title">
                        {{ $t("publish.otherDemands") }}
                    </div>
                    <el-input :placeholder="$t('publish.inputStr')" :rows="2" type="textarea"
                              v-model="attr2.financingAppeal">
                    </el-input>
                </div>
                <div class="three_item other-financing" style="margin-bottom: 50px">
                    <div class="three_title">
                        {{ $t("publish.brief") }}
                    </div>
                    <el-input :placeholder="$t('publish.inputStr')" :rows="2" type="textarea"
                              v-model="attr2.projectIntro">
                    </el-input>
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.releaseMode") }}
                    </div>
                    <div class="choose_way">
                        <img alt="" class="cur" src="../../assets/images/radio_s2@2x.png" v-if="attr2.publishType == 1"
                             width="16">
                        <img @click="attr2.publishType = 1" alt="" class="cur" src="../../assets/images/radio_n2@2x.png"
                             v-else width="16">
                        <span class="choose_title">{{ $t("publish.publicRelease") }}</span>
                        <span class="choose_hint">{{ $t("publish.vipSketch") }}</span>
                        <img @click="attr2.isHiddenIssuer = 1"
                             alt="" class="cur" src="../../assets/images/radio_s@2x.png" style="margin-bottom: 1px;"
                             v-if="attr2.isHiddenIssuer==2" width="14px">
                        <img @click="attr2.isHiddenIssuer = 2" alt="" class="cur"
                             src="../../assets/images/radio_n@2x.png" style="margin-bottom: 1px;" v-else width="14px">

                        <span class="choose_title_two">{{ $t("publish.hide") }}</span>
                    </div>
                    <div class="choose_way">
                        <img alt="" class="cur" src="../../assets/images/radio_s2@2x.png" v-if="attr2.publishType == 2"
                             width="16">
                        <img @click="()=>{this.attr2.publishType = 2}" alt="" class="cur"
                             src="../../assets/images/radio_n2@2x.png" v-else width="16">
                        <span class="choose_title">{{ $t("publish.private") }}</span>
                        <span class="choose_hint" style="margin-left: 20px">
              <el-tooltip :content="dictLabel" placement="top">
                <img alt="" src="../../assets/images/icon_why@2x.png" style="margin-right: 0;margin-bottom: 2px;"
                     width="13">
              </el-tooltip>
              {{ $t("publish.whatSketch") }}
            </span>
                    </div>
                </div>
            </div>
            <!--找智慧-->
            <div class="three_box" v-else-if="this.detailData.projectType==3">
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.projectName") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" type="text" v-model="attr3.projectName">
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show7">项目名称不能为空</span>-->
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.budget") }}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" oninput="value=value.replace(/[^\d.]/g,'')"
                               type="text" v-model="attr3.money">
                        <div class="fr">{{ $t("publish.dollar") }}</div>
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:-30px;" v-show="show8">预算不能为空</span>-->
                </div>
                <div class="three_item other-financing" style="margin-bottom: 50px">
                    <div class="three_title">
                        {{ $t("publish.brief") }}
                    </div>
                    <el-input
                            :placeholder="$t('publish.inputStr')"
                            :rows="2"
                            type="textarea"
                            v-model="attr3.projectIntro">
                    </el-input>
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.releaseMode") }}
                    </div>
                    <div class="choose_way">
                        <img alt=""
                             class="cur" src="../../assets/images/radio_s2@2x.png" v-if="attr3.publishType == 1"
                             width="16">
                        <img @click="attr3.publishType = 1"
                             alt="" class="cur" src="../../assets/images/radio_n2@2x.png" v-else width="16">
                        <span class="choose_title">{{ $t("publish.publicRelease") }}</span>
                        <span class="choose_hint">{{ $t("publish.vipSketch") }}</span>
                        <img @click="attr3.isHiddenIssuer = 1"
                             alt="" class="cur" src="../../assets/images/radio_s@2x.png" style="margin-bottom: 1px;"
                             v-if="attr3.isHiddenIssuer==2" width="14px">
                        <img @click="attr3.isHiddenIssuer = 2" alt="" class="cur"
                             src="../../assets/images/radio_n@2x.png" style="margin-bottom: 1px;" v-else width="14px">
                        <span class="choose_title_two">{{ $t("publish.hide") }}</span>
                    </div>
                    <div class="choose_way">
                        <img alt=""
                             class="cur" src="../../assets/images/radio_s2@2x.png" v-if="attr3.publishType == 2"
                             width="16">
                        <img @click="()=>{this.attr3.publishType = 2}"
                             alt="" class="cur" src="../../assets/images/radio_n2@2x.png" v-else width="16">
                        <span class="choose_title">{{ $t("publish.private") }}</span>
                        <span class="choose_hint" style="margin-left: 20px">
              <el-tooltip :content="dictLabel" placement="top">
                <img alt="" src="../../assets/images/icon_why@2x.png" style="margin-right: 0;margin-bottom: 2px;"
                     width="13">
              </el-tooltip>
              {{ $t("publish.whatSketch") }}
            </span>
                    </div>
                </div>
            </div>

            <!--   four   -->
            <div class="four_box">
                <div class="four_title">
                    <h2>{{ $t("publish.content") }}</h2>
                    <p>{{ $t("publish.whole") }}</p>
                </div>
                <div class="rich_box">
                    <!--<RichText ref='rich' :placeholder="$t('publish.text')"></RichText>-->
                    <!--<editor :api-key="apikey" :init="editorInit" v-model="richContent" />-->
                    <quill-editor
                            :options="editorOption"
                            @blur="onEditorBlur($event)"
                            @focus="onEditorFocus($event)"
                            @ready="onEditorReady($event)"
                            ref="myQuillEditor"
                            v-model="richContent"/>
                </div>
            </div>
            <!--   预约时间   -->
            <div class="seven_box" v-if="$route.query.isInterView">
                <h2>{{ $t("vipRecommend.appointmentTime") }}</h2>
                <p>{{ $t("publish.fiveTime") }}</p>
                <div class="seven_item">
                    <div class="fl" style="width: 90%">
                        <el-date-picker :picker-options="pickerOptions" :placeholder="$t('myWallet.selectADate')"
                                        style="width: 100%"
                                        type="datetime" v-model="inviteTime1" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl" style="width: 90%">
                        <el-date-picker :picker-options="pickerOptions" :placeholder="$t('myWallet.selectADate')"
                                        style="width: 100%"
                                        type="datetime" v-model="inviteTime2" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl" style="width: 90%">
                        <el-date-picker :picker-options="pickerOptions" :placeholder="$t('myWallet.selectADate')"
                                        style="width: 100%"
                                        type="datetime" v-model="inviteTime3" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl" style="width: 90%">
                        <el-date-picker :picker-options="pickerOptions" :placeholder="$t('myWallet.selectADate')"
                                        style="width: 100%"
                                        type="datetime" v-model="inviteTime4" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl" style="width: 90%">
                        <el-date-picker :picker-options="pickerOptions" :placeholder="$t('myWallet.selectADate')"
                                        style="width: 100%"
                                        type="datetime" v-model="inviteTime5" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
            </div>
            <!--   five   -->
            <div class="five_box">
                <div class="five_item">
                    <div class="five_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.phone") }}
                    </div>
                    <div>
                        <div class="area-code" style="margin-top: 15px;width: 536px">
                            <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="mobile">
                                <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                           v-model="mobileCode">
                                    <el-option :key="id"
                                               :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                               :value="item.code"
                                               v-for="(item, id) in areaList.phoneCountriesCodeList"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                        <!--<span style="color:red;position: absolute;margin-top:10px;" v-show="show9">手机号不能为空</span>-->
                    </div>
                </div>
                <div class="five_item">
                    <div class="five_title">
                        <span style="color:red;">*</span>
                        {{ $t("publish.mailbox") }}
                    </div>
                    <div class="five_input">
                        <input :placeholder="$t('publish.input')" class="" type="text" v-model="email">
                    </div>
                    <!--<span style="color:red;position: absolute;margin-top:10px;" v-show="show10">邮箱不能为空</span>-->
                </div>
                <div class="five_item">
                    <div class="five_title">
                        <span style="color:red;">*</span>
                        Whatsapp
                    </div>

                    <div class="area-code" style="margin-top: 15px;width: 536px">
                        <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="whatsapp">
                            <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                       v-model="whatsappCode">
                                <el-option :key="index"
                                           :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                           :value="item.code"
                                           v-for="(item, index) in areaList.phoneCountriesCodeList"></el-option>
                            </el-select>
                        </el-input>
                    </div>
                </div>
                <div @click="editProject" class="save_btn cur">
                    <p>{{ $t("myProject.editor") }}</p>
                </div>
            </div>
            <!--   six   -->
            <div class="six_box">
                <div class="fl six_left">
                    <h2>VIP</h2>
                    <p>{{ $t("publish.bottomStr") }}</p>
                </div>
                <div class="fr six_right">
                    Upgrade VIP
                </div>
            </div>
        </div>
        <div class="pop" v-if="isPop">
            <div class="pop_box auto">
                <h2>{{ $t("publish.tips") }}</h2>
                <p class="pop_hint_one">{{ $t("publish.unPublished") }}</p>
                <br>
                <p class="pop_hint_two">
                    {{projectIndex == 1 ? $t("publish.switch1") : projectIndex == 2 ? $t("publish.switch2") :
                    $t("publish.switch3")}}
                </p>
                <div class="pop_btn">
                    <div @click="isPop = false" class="cur cancel_btn fl">{{ $t("publish.cancel") }}</div>
                    <div @click="changeTab" class="cur sure_btn fl">{{ $t("publish.determine") }}</div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <!--<video src="http://blessboss.oss-us-west-1.aliyuncs.com/projectVideo/1619231782015.mp4"></video>-->

    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import RichText from '@/components/richText'
    import UploadBanner from "@/components/project/UploadBanner";
    import {
        insertProjectForSomeone,
        insertProjectForFinancing,
        insertProjectForWisdom,
        projectDetails
    } from "@/api/projectAbout";
    import {projectDictTypeFilter, findVipRecommen} from "@/api/commonApi";
    import Loading from '@/components/loading';
    import {uploadingForRichText, uploadingProjectImg, uploadingProjectVideo} from '@/api/uploadApi'
    import {selectCountriesCodeInfo} from '@/api/userApi'
    import {Loading as elLoading} from "element-ui";
    import {tinyMixin} from "@/mixins/tinymce";
    import {quillMixin} from "@/mixins/quillEdit";
    import {dateLimit} from "@/mixins/date_limit";

    import {
        editInterviewForPerson,
        editProjectForFinancing,
        editProjectForSomeone,
        editProjectForWisdom,
        selectMainProjectBaseInfoById,
        selectThreeProjectById
    } from "@/api/myProjectApi";
    import {chargeThreeMinuteProject} from "@/api/payment";


    let loadingInstance = null;

    export default {
        name: "AddProject",
        mixins: [tinyMixin, quillMixin, dateLimit],
        data() {
            return {
                detailData: '',
                dictLabel: '',
                //文件类型
                imgType: '.jpg,.jpeg,.png,.JPG,.JPEG',
                gifType: '.GIF,.gif',
                videoType: '.mp4,MP4',
                projectIndex: 1,
                projectType: 1,
                isPop: false,
                coverChecked: 'img',//封面选择 img、GIF、video
                projectCoverUrl: '',
                isLoading: false,
                mobileCode: '86',
                whatsappCode: '86',
                areaList: [],
                options: [],
                mobile: '',
                email: '',
                whatsapp: '',
                attr1: {
                    projectName: '',
                    money: '',
                    projectIntro: '',
                    publishType: 1,
                    isHiddenIssuer: 1,
                },
                attr2: {
                    projectName: '',
                    financingStage: '',
                    money: '',
                    equity: 0,
                    projectIntro: '',
                    financingAppeal: '',
                    publishType: 1,
                    isHiddenIssuer: 1,
                },
                attr3: {
                    projectName: '',
                    money: '',
                    projectIntro: '',
                    publishType: 1,
                    isHiddenIssuer: 1,
                },
                inviteTime1: '',//预约时间
                inviteTime2: '',//预约时间
                inviteTime3: '',//预约时间
                inviteTime4: '',//预约时间
                inviteTime5: '',//预约时间
            };

        },
        computed: {
            actionUrl() {
                return this.coverChecked == 'video' ? uploadingProjectVideo : uploadingProjectImg;
            },
            coverType() {

                return this.coverChecked == 'video' ? 1 : 2;
            },
            mediaType() {
                return this.coverChecked == 'img' ? this.imgType : this.coverChecked == 'gif' ? this.gifType : this.videoType;
            },
        },
        components: {
            BreadcrumbNav,
            RichText,
            UploadBanner,
            Loading,
        },
        created() {
            console.log("this.$route.query.isInterview====", this.$route.query.isInterview)
            if (this.$route.query.isInterview == "true") {
                this.getInterviewData();
            } else {
                console.log("ashdkjasbndkjanskjdnaklsndklanslkdnalskndlkasndklasndkla")
                this.getData();
            }
            //获取地区编号
            this.getArea();
            this.projectType = this.$route.query.projectIndex ? this.$route.query.projectIndex : 1;
            this.getFinancingStage();//获取融资阶段下拉信息
            this.getFindVipRecommen();
        },
        methods: {
            //请求详情数据
            getData() {
                let formData = {
                    projectId: this.$route.params.id,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                selectMainProjectBaseInfoById(formData).then((res) => {
                    this.isLoading = false;
                    this.detailData = res.data ?? {};
                    this.projectType = this.detailData.projectType;
                    this.projectCoverUrl = this.detailData.projectCoverUrl;
                    this.$refs.uploadBanner.setList(this.detailData.projectBanner ? this.detailData.projectBanner.split(',') : []);
                    if (this.detailData.projectType == 1) {
                        this.richContent = this.detailData.details;
                        this.mobile = this.detailData.mobile;
                        this.mobileCode = this.detailData.mobileCode;
                        this.email = this.detailData.email;
                        this.whatsapp = this.detailData.whatsapp;
                        this.whatsappCode = this.detailData.whatsappCode;
                        this.attr1 = {
                            projectName: this.detailData.projectName,
                            money: this.detailData.money,
                            projectIntro: this.detailData.projectIntro,
                            publishType: this.detailData.publishType ?? 1,
                            isHiddenIssuer: this.detailData.isHiddenIssuer ?? 1,
                        };
                    } else if (this.detailData.projectType == 2) {
                        this.richContent = this.detailData.details;
                        this.mobile = this.detailData.mobile;
                        this.mobileCode = this.detailData.mobileCode;
                        this.email = this.detailData.email;
                        this.whatsapp = this.detailData.whatsapp;
                        this.whatsappCode = this.detailData.whatsappCode;
                        this.attr2 = {
                            projectName: this.detailData.projectName,
                            money: this.detailData.money,
                            projectIntro: this.detailData.projectIntro,
                            publishType: this.detailData.publishType ?? 1,
                            isHiddenIssuer: this.detailData.isHiddenIssuer ?? 1,
                            financingAppeal: this.detailData.financingAppeal,
                            financingStage: this.detailData.financingStage,
                            equity: this.detailData.equity,
                        };
                    } else if (this.detailData.projectType == 3) {
                        this.richContent = this.detailData.details;
                        this.mobile = this.detailData.mobile;
                        this.mobileCode = this.detailData.mobileCode;
                        this.email = this.detailData.email;
                        this.whatsapp = this.detailData.whatsapp;
                        this.whatsappCode = this.detailData.whatsappCode;
                        this.attr3 = {
                            projectName: this.detailData.projectName,
                            money: this.detailData.money,
                            projectIntro: this.detailData.projectIntro,
                            publishType: this.detailData.publishType ?? 1,
                            isHiddenIssuer: this.detailData.isHiddenIssuer ?? 1,
                        };
                    }

                })
            },
            getInterviewData() {
                let formData = {
                    projectId: this.$route.params.id,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                selectThreeProjectById(formData).then((res) => {
                    this.isLoading = false;
                    this.detailData = res.data ?? {};
                    this.projectType = this.detailData.projectType;
                    this.projectCoverUrl = this.detailData.projectCoverUrl;
                    if (this.detailData.projectBannner.length > 1) {
                        this.$refs.uploadBanner.setList(this.detailData.projectBannner ? this.detailData.projectBannner.split(',') : []);
                    }
                    this.inviteTime1 = this.detailData.inviteTime1;//预约时间
                    this.inviteTime2 = this.detailData.inviteTime2;//预约时间
                    this.inviteTime3 = this.detailData.inviteTime3;//预约时间
                    this.inviteTime4 = this.detailData.inviteTime4;//预约时间
                    this.inviteTime5 = this.detailData.inviteTime5;//预约时间
                    if (this.detailData.projectType == 1) {
                        this.richContent = this.detailData.details;
                        this.mobile = this.detailData.mobile;
                        this.mobileCode = this.detailData.mobileCode;
                        this.email = this.detailData.email;
                        this.whatsapp = this.detailData.whatsapp;
                        this.whatsappCode = this.detailData.whatsappCode;
                        this.attr1 = {
                            projectName: this.detailData.projectName,
                            money: this.detailData.money,
                            projectIntro: this.detailData.projectIntro,
                            publishType: this.detailData.publishType ?? 1,
                            isHiddenIssuer: this.detailData.isHiddenIssuer ?? 1,
                        };
                    } else if (this.detailData.projectType == 2) {
                        this.richContent = this.detailData.details;
                        this.mobile = this.detailData.mobile;
                        this.mobileCode = this.detailData.mobileCode;
                        this.email = this.detailData.email;
                        this.whatsapp = this.detailData.whatsapp;
                        this.whatsappCode = this.detailData.whatsappCode;
                        this.attr2 = {
                            projectName: this.detailData.projectName,
                            money: this.detailData.money,
                            projectIntro: this.detailData.projectIntro,
                            publishType: this.detailData.publishType ?? 1,
                            isHiddenIssuer: this.detailData.isHiddenIssuer ?? 1,
                            financingAppeal: this.detailData.financingAppeal,
                            financingStage: this.detailData.financingStage,
                            equity: this.detailData.equity,
                        };
                    } else if (this.detailData.projectType == 3) {
                        this.richContent = this.detailData.details;
                        this.mobile = this.detailData.mobile;
                        this.mobileCode = this.detailData.mobileCode;
                        this.email = this.detailData.email;
                        this.whatsapp = this.detailData.whatsapp;
                        this.whatsappCode = this.detailData.whatsappCode;
                        this.attr3 = {
                            projectName: this.detailData.projectName,
                            money: this.detailData.money,
                            projectIntro: this.detailData.projectIntro,
                            publishType: this.detailData.publishType ?? 1,
                            isHiddenIssuer: this.detailData.isHiddenIssuer ?? 1,
                        };
                    }

                })
            },
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },

            //清理封面
            clearCover() {
                this.$refs.uploadCover.clearFiles();
                this.projectCoverUrl = '';
            },
            //清空数据
            clearData() {
                this.richContent = '';
                this.coverChecked = 'img';
                // this.projectCoverUrl='';
                this.mobile = '';
                this.email = '';
                this.whatsapp = '';
                this.attr1.projectName = '';
                this.attr1.money = '';
                this.attr1.projectIntro = '';
                this.attr1.publishType = 1;
                this.attr1.isHiddenIssuer = 1;
                this.attr2.projectName = '';
                this.attr2.money = '';
                this.attr2.projectIntro = '';
                this.attr2.publishType = 1;
                this.attr2.isHiddenIssuer = 1;
                this.attr2.financingStage = '';
                this.attr2.financingAppeal = '';
                this.attr2.equity = 0;
                this.attr3.projectName = '';
                this.attr3.money = '';
                this.attr3.projectIntro = '';
                this.attr3.publishType = 1;
                this.attr3.isHiddenIssuer = 1;
            },
            //切换找人、找钱、找智慧
            changeIndex(index) {
                this.isPop = true;
                this.projectIndex = index;
            },
            changeTab() {
                this.clearData();
                this.clearCover();
                this.$refs.uploadBanner.clear();
                this.isPop = false;
                this.projectType = this.projectIndex;
            },
            //切换封面类型
            checkCover(type) {
                this.clearCover();
                this.coverChecked = type;
            },
            //封面上传成功钩子
            handleSuccess(response, file, fileList) {
                loadingInstance.close();
                if (response.code == 200) {
                    this.projectCoverUrl = response.data.path;
                    console.log("Cover address" + this.projectCoverUrl)//封面地址
                } else {
                    this.$message.error(response.msg)
                }
            },
            handleBefore(file) {
                loadingInstance = elLoading.service({
                    text: 'Desperately uploading',//拼命上传中
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            //编辑项目
            editProject() {
                if (this.$route.query.isInterview == "true") {
                    this.editInterview();
                } else {
                    if (this.projectType == 1) {
                        this.editProjectOfPerson();
                    } else if (this.projectType == 2) {
                        this.editProjectOfMoney();
                    } else if (this.projectType == 3) {
                        this.editProjectForWisdom();
                    } else {
                        this.$message("Item type exception!");//项目类型异常
                    }
                }

            },

            async editInterview() {
                let formData = {
                    //公有字段
                    projectId: this.$route.params.id,
                    projectType: this.projectType,
                    coverType: this.coverType,
                    projectCoverUrl: this.projectCoverUrl,
                    projectBanner: this.$refs.uploadBanner.getUrls(),
                    projectName: this.projectType == 1 ? this.attr1.projectName : this.projectType == 2 ? this.attr2.projectName : this.attr3.projectName,//项目/职位名称
                    projectIntro: this.projectType == 1 ? this.attr1.projectIntro : this.projectType == 2 ? this.attr2.projectIntro : this.attr3.projectIntro,
                    publishType: this.projectType == 1 ? this.attr1.publishType : this.projectType == 2 ? this.attr2.publishType : this.attr3.publishType,//发布方式
                    isHiddenIssuer: this.projectType == 1 ? this.attr1.isHiddenIssuer : this.projectType == 2 ? this.attr2.isHiddenIssuer : this.attr3.isHiddenIssuer,//是否隐藏发布人
                    details: this.richContent,
                    inviteTime1: this.inviteTime1,//预约时间
                    inviteTime2: this.inviteTime2,//预约时间
                    inviteTime3: this.inviteTime3,//预约时间
                    inviteTime4: this.inviteTime4,//预约时间
                    inviteTime5: this.inviteTime5,//预约时间
                    mobile: this.mobile,
                    email: this.email,
                    whatsApp: this.whatsapp,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    invitationMessage: '',//关于预约给客服的留言
                    money: this.projectType == 1 ? this.attr1.money : this.projectType == 2 ? this.attr2.money : this.attr3.money,//待遇/融资金额/预算
                    exchangeCost: '1',//沟通费用
                    exchangeType: '1',//沟通方式（1、音频； 2视频）
                    inviteeId: this.detailData.inviteeId,//受邀人Id
                    inviteeName: this.detailData.inviteeName,//受邀人名称
                    token: localStorage.getItem("token"),
                };
                if (this.attr2.financingStage) {
                    formData.financingStage = this.attr2.financingStage;//融资阶段
                }
                if (this.attr2.equity) {
                    formData.equity = this.attr2.equity;//出让股权（仅仅传数字）
                }
                if (this.attr2.financingAppeal) {
                    formData.financingAppeal = this.attr2.financingAppeal;//其他融资诉求
                }
                this.isLoading = true;
                editInterviewForPerson(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //编辑项目——找人
            editProjectOfPerson() {
                if (this.projectCoverUrl == '' || this.projectCoverUrl == null) {
                    this.$message.warning(this.$i18n.locale == 'zh' ? "请选择一张封面图" : "Please select a cover sheet！")//请选择一张封面图
                    return
                }
                if (this.$refs.uploadBanner.getUrls() == '' || this.$refs.uploadBanner.getUrls() == null) {
                    this.$message.warning(this.$i18n.locale == 'zh' ? "请至少选择一张轮播图" : "Please select at least one carousel！")//请至少选择一张轮播图
                    return
                }
                let formData = {
                    projectId: this.$route.params.id,
                    projectName: this.attr1.projectName,//项目名称/职位名称
                    projectType: this.projectType,
                    coverType: this.coverType,
                    projectCoverUrl: this.projectCoverUrl,
                    details: this.richContent,
                    mobile: this.mobile,
                    email: this.email,
                    whatsapp: this.whatsapp,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    money: this.attr1.money,//待遇
                    isHiddenIssuer: this.attr1.isHiddenIssuer,
                    projectBanner: this.$refs.uploadBanner.getUrls(),
                    projectIntro: this.attr1.projectIntro,
                    publishType: this.attr1.publishType,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                editProjectForSomeone(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //编辑项目——找钱
            editProjectOfMoney() {
                if (this.projectCoverUrl == '' || this.projectCoverUrl == null) {
                    this.$message.warning("Please select a cover sheet！")//请选择一张封面图
                    return

                }
                if (this.$refs.uploadBanner.getUrls() == '' || this.$refs.uploadBanner.getUrls() == null) {
                    this.$message.warning("Please select at least one carousel！")//请至少选择一张轮播图
                    return

                }
                let formData = {
                    projectId: this.$route.params.id,
                    projectName: this.attr2.projectName,//项目名称
                    projectType: this.projectType,
                    coverType: this.coverType,
                    projectCoverUrl: this.projectCoverUrl,
                    details: this.richContent,
                    mobile: this.mobile,
                    email: this.email,
                    whatsapp: this.whatsapp,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    money: this.attr2.money,//融资金额
                    equity: this.attr2.equity,//出让股权（仅仅传数字）
                    financingAppeal: this.attr2.financingAppeal,//其他融资诉求
                    financingStage: this.attr2.financingStage,//融资阶段
                    isHiddenIssuer: this.attr2.isHiddenIssuer,
                    projectBanner: this.$refs.uploadBanner.getUrls(),
                    projectIntro: this.attr2.projectIntro,
                    publishType: this.attr2.publishType,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                editProjectForFinancing(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            },
            //编辑项目——找智慧
            editProjectForWisdom() {
                console.log(this.projectCoverUrl)
                if (this.projectCoverUrl == '' || this.projectCoverUrl == null) {
                    this.$message.warning("Please select a cover sheet！")//请选择一张封面图
                    return
                }
                console.log(this.$refs.uploadBanner.getUrls())
                if (this.$refs.uploadBanner.getUrls() == '' || this.$refs.uploadBanner.getUrls() == null) {
                    this.$message.warning("Please select at least one carousel！")//请至少选择一张轮播图
                    return

                }
                let formData = {
                    projectId: this.$route.params.id,
                    projectName: this.attr3.projectName,//项目名称/职位名称
                    projectType: this.projectType,
                    coverType: this.coverType,
                    projectCoverUrl: this.projectCoverUrl,
                    details: this.richContent,
                    mobile: this.mobile,
                    email: this.email,
                    whatsapp: this.whatsapp,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    money: this.attr3.money,//预算
                    financingStage: 0,//融资阶段(非融资类均为0)
                    isHiddenIssuer: this.attr3.isHiddenIssuer,
                    projectBanner: this.$refs.uploadBanner.getUrls(),
                    projectIntro: this.attr3.projectIntro,
                    publishType: this.attr3.publishType,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                editProjectForWisdom(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //获取融资阶段下拉内容
            getFinancingStage() {
                projectDictTypeFilter({dictType: "financing_stage"}).then((res) => {
                    res.data.forEach((e) => {
                        let object = {
                            value: e.dictValue,
                            label: e.dictLabel,
                        };
                        this.options.push(object);
                    });
                })
            },
            getFindVipRecommen() {
                findVipRecommen().then((res) => {
                    if (res.code == 200) {
                        this.dictLabel = res.msg;
                    } else {
                        this.dictLabel = '';
                    }
                })
            }
        },
    }
</script>

<style scoped>

    @import "../../assets/css/quillFont.css";

    .add_box {
        width: 1200px;
    }

    .Add_nav {
        margin-top: 31px;
        margin-bottom: 44px;
    }

    .nav_item {
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 20px;
        transition: .3s all;
    }

    .nav_active {
        color: #FFFFFF;
        background: #000000;
    }

    .nav_item:hover {
        color: #FFFFFF;
        background: #000000;
    }

    .upload_one {
        width: 695px;
        height: 460px;
        background: #FFFFFF;
        border: 1px dashed #E5E5E5;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
    }

    .upload_one li {
        width: 33%;
        height: 120px;
        position: relative;
    }

    .upload_hint_img {
        line-height: 120px;
        padding-left: 22px;
        padding-right: 9px;
    }

    .upload_hint {
        width: 140px;
        box-sizing: border-box;
    }

    .upload_hint h2 {
        font-size: 13px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
        margin-bottom: 5px;
    }

    .upload_hint p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
    }

    .line {
        position: absolute;
        right: 0;
        top: 25px;
        display: block;
        width: 0px;
        height: 79px;
        border: 1px solid #E5E5E5;
    }

    .upload_active {
        background: #000000;
    }

    .upload_active h2, .upload_active p {
        color: #FFFFFF !important;
    }

    .upload_active .line {
        display: none !important;
    }

    .upload_hint_box {
        text-align: center;
        /*padding-top: 28px;*/
        box-sizing: border-box;
        position: relative;
        height: 335px;

    }

    .upload-demo {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        /*opacity: 0;*/

    }

    .upload_hint_box h2 {
        font-size: 27px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 32px;
        color: #000000;
        text-align: center;
        margin-top: 26px;
    }


    .upload_hint_box p {
        font-size: 17px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        margin-top: 12px;
    }

    .upload_hint_box div {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
        margin-top: 9px;
    }

    .upload_two {
        margin-top: 18px;
    }

    .upload_two li {
        width: 114px;
        height: 75px;
        background: #FFFFFF;
        border-radius: 4px;
    }

    .three_box {
        margin-top: 52px;
    }

    .three_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 22px;
    }

    .three_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .input_box {
        width: 695px;
        height: 50px;
        line-height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 52px;
    }

    .input_box input {
        width: 80%;
        background: #FAFAFA;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }

    .input_box div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }

    .choose_way {
        line-height: 25px;
        margin-bottom: 32px;
        margin-left: 31px;
    }

    .choose_way img {
        margin-bottom: 4px;
        margin-right: 10px;
    }

    .choose_way .choose_title {
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #000000;
        padding-right: 8px;
    }

    .choose_way .choose_hint {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #949494;
        padding-right: 40px;
    }

    .choose_way .choose_title_two {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .four_box {
        margin-top: 66px;
    }

    .four_title {
        margin-left: 10px;
    }

    .four_title h2 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 14px;
    }

    .four_title p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
    }

    .rich_box {
        width: 720px;
        height: 667px;
        border-radius: 4px;
        margin-bottom: 54px;
    }

    /*.rich_box {
      width: 756px;
      height: 667px;
      background: #EEEEEE;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      margin-top: 18px;
      margin-left: 10px;
    }*/

    .five_box {
        margin-top: 30px;
    }

    .five_item {
        margin-bottom: 31px;
    }

    .five_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 22px;
    }

    .five_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .five_input {
        width: 536px;
        height: 50px;
        line-height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
    }

    .five_input input {
        background: #FAFAFA;
        width: 300px;
    }

    .five_input .numberInput {
        height: 22px;
        margin-top: 13px;
        background: #FAFAFA;
        margin-left: 53px;
        border-left: 1px solid #D6D6D6;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        padding-left: 45px;
    }

    .save_btn {
        width: 162px;
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 52px;
    }

    .six_box {
        width: 1200px;
        height: 150px;
        border-radius: 4px;
        background: url("../../assets/images/vipbg@2x.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 75px;
        margin-bottom: 30px;
    }

    .six_left {
        margin-left: 112px;
        padding-top: 43px;

    }

    .six_left h2 {
        font-size: 27px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 32px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    .six_left p {
        font-size: 17px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
    }

    .six_right {
        width: 150px;
        height: 49px;
        border-radius: 25px;
        box-sizing: border-box;
        line-height: 47px;
        text-align: center;
        margin-right: 209px;
        margin-top: 59px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
    }

    .pop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
        z-index: 9999999;
    }

    .pop_box {
        width: 600px;
        height: 353px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-top: 16%;
    }

    .pop_box h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        text-align: center;
        padding-top: 42px;
        margin-bottom: 46px;
    }

    .pop_box p {
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 33px;
        color: #000000;
        text-align: center;
    }

    .pop_box .pop_btn {
        margin-top: 60px;
    }

    .pop_btn div:nth-child(1) {
        margin-left: 146px;
        margin-right: 29px;
        border: 1px solid #E5E5E5 !important;
    }

    .pop_btn div {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        text-align: center;
    }

    /*shangchuan*/
    .cover-show {
        position: relative;
        overflow: hidden;
    }

    .cover-show img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .cancel-btn {
        display: none;
        position: absolute;
        right: 20px;
        top: 0;
    }

    .cover-show:hover .cancel-btn {
        display: block;
    }

    .cover-show .cancel-btn {
        position: absolute;
    }

    .input_box_number {
        width: 695px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding-left: 26px;
        box-sizing: border-box;
        margin-left: 11px;
    }

    .input_box_number span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .input_box_number input {
        margin-left: 53px;
        padding-left: 45px;
        height: 22px;
        background: #FAFAFA;
        border-left: 1px solid #d6d6d6;
        width: 500px;
        box-sizing: border-box;
    }

    /*预约时间*/
    .seven_box {
        margin-top: 60px;
    }

    .seven_box > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .seven_box > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 7px;
        margin-bottom: 26px;
    }

    .seven_item {
        width: 537px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .seven_item img {
        margin-top: 15px;
    }
</style>
